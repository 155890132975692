<template>
  <div class="input-wrapper row search">
    <Icon :path="mdiMagnify" :size="32" v-if="this.showIcon" />

    <input
      type="text"
      :value="this.text"
      :placeholder="this.placeholder"
      @input="$emit('update:text', $event.target.value)" />

    <button v-if="this.showButton" @click="$emit('search', this.text)">
      Search
    </button>
  </div>
</template>

<script>
  import { mdiMagnify } from "@mdi/js";

  export default {
    props: {
      text: {
        type: String,
      },
      placeholder: {
        type: String,
        default: "Enter a search term",
      },
      showIcon: {
        type: Boolean,
        default: true,
      },
      showButton: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["update:text", "search"],

    data() {
      return {
        mdiMagnify,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .theme--light {
    .search {
      color: $col_black;

      input {
        color: $col_black;
        border-color: $col_offblack2;
      }

      ::placeholder {
        color: $col_offblack2;
        opacity: 1;
      }
      ::-ms-input-placeholder {
        color: $col_offblack2;
      }
    }
  }

  .theme--dark {
    .search {
      color: $col_white;

      input {
        color: $col_white;
        border-color: $col_offwhite2;
      }

      ::placeholder {
        color: $col_offwhite2;
        opacity: 1;
      }
      ::-ms-input-placeholder {
        color: $col_offwhite2;
      }
    }
  }

  .search {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: stretch;

    input {
      min-width: auto !important;
      width: auto !important;
      flex: 1;
      flex-grow: 1;
    }

    button: {
      min-width: auto !important;
      width: auto !important;
      flex: 1;
      flex-grow: 0;
    }
  }
</style>
