<template>
  <section>
    <div v-if="loading" class="loading-spinner_container">
      <LoadingSpinner />
    </div>
    <div v-else>
      <h2>Locations</h2>
      <Search v-model:text="this.search" />
      <ul class="venues">
        <li v-for="venue in getVenues" :key="venue.id" class="venues__details">
          <ImageCarousel
            :assets="getVenueCdnUrls(venue.thumbnails)"
            :alt="venue.name"
            is-clickable
            @click="
              this.$router.push({
                name: 'venue',
                params: {
                  venueCode: formatVenueCode(venue),
                },
              })
            " />
          <router-link
            :to="{
              name: 'venue',
              params: {
                venueCode: formatVenueCode(venue),
              },
            }">
            <div class="venues__info">
              <p class="venues__name">{{ venue.name }}</p>
              <span v-if="venue.address" class="venues__address">
                <Icon :path="mdiMapMarker" :size="16" />
                {{ formatAddress(venue.address) }}
              </span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
  import store from "@/store";
  import Enumerable from "linq";
  import { mdiMapMarker } from "@mdi/js";
  import Search from "@/components/search/Search.vue";
  import ImageCarousel from "@/components/Images/ImageCarousel.vue";
  import { formatAddress } from "@tucktrucks/platform-base-public";

  export default {
    components: { Search, ImageCarousel },
    data() {
      return {
        venues: null,
        loading: false,
        availableVenues: null,
        search: "",
        mdiMapMarker,
        formatAddress,
      };
    },

    computed: {
      getVenues() {
        if (this.search == "") {
          return this.availableVenues;
        }

        const regex = new RegExp(this.search, "gi");

        return Enumerable.from(this.availableVenues)
          .where(
            (x) =>
              regex.test(x.name) ||
              regex.test(x.address?.addressLine1) ||
              regex.test(x.address?.addressLine2) ||
              regex.test(x.address?.city) ||
              regex.test(x.address?.postalCode)
          )
          .toArray();
      },
    },

    methods: {
      getVenueCdnUrls(thumbnails) {
        return thumbnails.map(
          ({ cdnUrl }) => `${process.env.VUE_APP_CDN}` + "/" + cdnUrl
        );
      },
      formatVenueCode(venue) {
        const code = venue.code ? venue.code.toLowerCase() : "";
        return code;
      },
    },

    async beforeMount() {
      this.loading = true;
      try {
        await store.state.apiPublic.client.endpoints.venues
          .getAll()
          .then((response) => {
            const venues = response.data.data;
            this.venues = venues;

            const availableVenues = [];

            for (const venue of venues) {
              const { code, mode } = venue;
              if (code && mode == "Operational") {
                availableVenues.push(venue);
              }
            }
            this.availableVenues = availableVenues;
          });
      } catch (e) {
        window.log.error(e);
      } finally {
        this.loading = false;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .theme--light {
    h2 {
      color: $col_black;
    }

    .venues {
      &__details,
      &__info,
      &__name,
      &__address {
        color: $col_black;
      }
    }
  }

  .theme--dark {
    h2 {
      color: $col_white;
    }
    .venues {
      &__details,
      &__info,
      &__address {
        color: $col_offwhite2;
      }

      &__name {
        color: $col_white;
      }
    }
  }

  .venues {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @media screen and (min-width: $lg_desktop_max_width) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: $tablet_max_width) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $mobile_max_width) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__details {
      box-shadow: $card_shadow;
      border-radius: $card_radius;
      overflow: hidden;

      a {
        text-decoration: none;
      }
    }

    &__info {
      padding: 0.5rem 1rem 1rem;
    }

    &__name {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
    }

    &__address {
      font-size: 0.875rem;
      display: flex;
      align-items: baseline;
      .icon-wrapper {
        top: 3px;
      }
    }
  }
</style>
